import { StyledStepSelectCell } from './CampaignStepSelectSegments.styled'
import { TableColumnProps, Tooltip } from 'antd'
import { Avatar } from 'components/atoms/avatar'
import { LIST_COLOR_AVATAR } from 'constants/common'
import { SEGMENT_TYPE } from 'constants/segment'
import { TEMPLATE_TYPE } from 'constants/template'
import { SegmentQueryParams } from 'interfaces/segment'
import { segmentServices } from 'services/segment'
import { formatNumberToCurrency } from 'utils'

export const selectSegmentsTabs = [
  {
    label: 'Attribute Segment',
    key: SEGMENT_TYPE.ATTRIBUTE,
    children: null,
    disabled: [TEMPLATE_TYPE.LP_PAGE]
  },
  {
    label: 'Event Segment',
    key: SEGMENT_TYPE.EVENT,
    children: null,
    disabled: [TEMPLATE_TYPE.LP_PAGE]
  },
  {
    label: 'Static List',
    key: SEGMENT_TYPE.CSV,
    children: null,
    disabled: [TEMPLATE_TYPE.LP_PAGE]
  },
  {
    label: 'Mobile Segment',
    key: SEGMENT_TYPE.MOBILE,
    children: null,
    disabled: [TEMPLATE_TYPE.LP_PAGE]
  }
]

export const countSegmentByCampaignTemplateType = (
  template_type = '',
  record: any
): number => {
  if (!record?.count_data?.length) {
    return 0
  }
  return record?.count_data?.reduce((sum: number, countData: any) => {
    let countDataNumber = 0
    const {
      email = 0,
      appPushCount = 0,
      webPushCount = 0,
      whatsappCount = 0,
      zaloCount = 0,
      // phoneCount = 0,
      totalCount = 0,
      smsCount = 0
    } = countData || {}
    switch (template_type) {
      case TEMPLATE_TYPE.EMAIL: {
        countDataNumber = email
        break
      }
      case TEMPLATE_TYPE.APP_PUSH: {
        countDataNumber = appPushCount
        break
      }
      case TEMPLATE_TYPE.WEB_PUSH: {
        countDataNumber = webPushCount
        break
      }
      case TEMPLATE_TYPE.WHATSAPP: {
        countDataNumber = whatsappCount
        break
      }
      case TEMPLATE_TYPE.ZALO:
        countDataNumber = zaloCount
        break
      case TEMPLATE_TYPE.VNPT:
      case TEMPLATE_TYPE.TWILIO: {
        countDataNumber = smsCount
        break
      }
      case TEMPLATE_TYPE.LP_PAGE:
      default:
        countDataNumber = totalCount
        break
    }
    return sum + countDataNumber
  }, 0)
}

type NameComponentProps = {
  id?: string | number
  title?: string
}
const NameComponent = (props: NameComponentProps) => {
  return (
    <StyledStepSelectCell className="name">
      <Tooltip title={props?.title}>
        <h5 className="title">{props?.title}</h5>
      </Tooltip>
      <p className="id">ID: {props.id}</p>
    </StyledStepSelectCell>
  )
}

export const selectSegmentsColumns: Record<string, TableColumnProps<any>[]> = {
  condition: [
    {
      title: 'Segment',
      dataIndex: 'name',
      width: 60,
      render(_, record) {
        return <NameComponent id={record?.id} title={record?.name} />
      }
    },
    {
      title: 'Created by',
      dataIndex: 'created_by',
      width: 60,
      render(created_by, _record, index) {
        return (
          <Avatar
            size={32}
            name={`${created_by?.first_name} ${created_by?.last_name}`}
            email={created_by?.email || ''}
            background={LIST_COLOR_AVATAR[index % LIST_COLOR_AVATAR.length]}
          />
        )
      }
    },
    { title: 'Type', dataIndex: 'segment_type', width: 30 },
    { title: 'Source', dataIndex: 'source', width: 30 },
    {
      title: 'Users',
      dataIndex: 'num_of_users',
      width: 30,
      render(value) {
        return formatNumberToCurrency(value || 0)
      }
    },
    {
      title: 'Actions',
      dataIndex: 'id',
      width: 30
    }
  ],
  csv: [
    {
      title: 'Static list',
      dataIndex: 'name',
      width: 60,
      render(_, record) {
        return <NameComponent id={record?.id} title={record?.name} />
      }
    },
    {
      title: 'Created by',
      dataIndex: 'created_by',
      width: 60,
      render(created_by, _record, index) {
        return (
          <Avatar
            size={32}
            name={`${created_by?.first_name} ${created_by?.last_name}`}
            email={created_by?.email || ''}
            background={LIST_COLOR_AVATAR[index % LIST_COLOR_AVATAR.length]}
          />
        )
      }
    },
    { title: 'Type', dataIndex: 'segment_type', width: 30 },
    { title: 'Source', dataIndex: 'source', width: 30 },
    {
      title: 'Users',
      dataIndex: 'num_of_users',
      width: 30,
      render(value) {
        return formatNumberToCurrency(value || 0)
      }
    }
  ]
}

export const fetchStepSelectSegments = async (params: SegmentQueryParams) => {
  try {
    const newParams = { ...params }
    if (params.type === SEGMENT_TYPE.EVENT) {
      delete newParams.type
      newParams.types = [SEGMENT_TYPE.EVENT, SEGMENT_TYPE.EVENT_CUSTOM].join(
        ','
      )
    }
    const response: any = await segmentServices.getSegmentList(newParams)
    if (response._message && !response.data) {
      throw new Error(JSON.stringify(response))
    }

    return { ...response.data }
  } catch (error) {
    return {
      data: [],
      meta: {
        total_items: 0,
        total_page: 0,
        current_page: params.page,
        per_page: params.per_page
      }
    }
  }
}

export const fetchStepSegment = async (id?: string | number) => {
  if (!id) {
    return null
  }

  try {
    const response: any = await segmentServices.getSegment(id)
    if (response._message && !response.data) {
      throw new Error(JSON.stringify(response))
    }

    return response.data
  } catch (error) {
    return null
  }
}
