import DrawerCreateTemplate from '../DrawerCreateTemplate'
import {
  fetchStepSelectTemplates,
  fetchStepTemplate,
  selectTemplatesColumns,
  selectTemplatesTabs,
  templateStatusSelect
} from './CampaignStepSelectTemplate.helpers'
import {
  StyledSelectContainer,
  StyledStepSelectHeader,
  StyledStepSelectHeaderSearch,
  StyledStepSelectHeaderTabs,
  StyledStepSelectInputSearch,
  StyledStepSelectTabs
} from './CampaignStepSelectTemplate.styled'
import {
  Button,
  Form,
  Radio,
  TableColumnProps,
  TablePaginationConfig,
  message
} from 'antd'
import { DrawerPreviewTemplate } from 'components/atoms/drawer'
import { TableScroll } from 'components/atoms/table'
import { TEMPLATE_TYPE } from 'constants/template'
import {
  Ref,
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react'

export type CampaignStepSelectTemplatesParams = {
  site?: string | number
  type?: string
  keyword?: string
  pagination: TablePaginationConfig
  status?: string
  message_type?: string
}

export type CampaignStepSelectTemplatesProps = Pick<
  CampaignStepSelectTemplatesParams,
  'site' | 'type'
> & {
  disabled?: boolean
  loading?: boolean
  defaultValue?: number | null
  value?: number | null
  onChange?: (value: number | null) => void
}
const DetailComponent = (props: {
  record: any
  columns: TableColumnProps<any>[]
}) => {
  const { record, columns } = props
  return (
    <tr
      key={record.id}
      className="ant-table-row ant-table-row-level-0 ant-table-row-selected">
      <td className="ant-table-cell ant-table-selection-column">
        <Radio checked />
      </td>
      {columns.map((col, index) => {
        const key = col.dataIndex?.toString()
        const value = record?.[key as keyof typeof record] || ''
        const children = col?.render ? col.render(value, record, index) : value
        return (
          <td key={key} className="ant-table-cell">
            {children}
          </td>
        )
      })}
    </tr>
  )
}

const CampaignStepSelectTemplateWithoutRef = (
  props: CampaignStepSelectTemplatesProps,
  ref?: Ref<HTMLDivElement>
) => {
  const {
    site,
    type,
    // disabled,
    loading: loadingProps,
    defaultValue,
    value: valueProps,
    onChange
  } = props
  const debounce = useRef<NodeJS.Timeout | null>(null)
  const [preview, setPreview] = useState<any>()
  const [isOpenCreateTemplate, setIsOpenCreateTemplate] = useState(false)
  const [value, setValue] = useState(defaultValue || valueProps)
  const [detail, setDetail] = useState<any>()
  const [loading, setLoading] = useState(!!loadingProps)
  const [dataSource, setDataSource] = useState([])
  const [params, setParams] = useState<CampaignStepSelectTemplatesParams>({
    site,
    type: type || 'all',
    status: templateStatusSelect[type || TEMPLATE_TYPE.EMAIL],
    pagination: {
      position: [],
      total: 0,
      current: 1,
      pageSize: 10
    }
  })

  const form = Form.useFormInstance()

  const items = useMemo(() => {
    return selectTemplatesTabs.map((tab) => {
      return {
        ...tab,
        disabled: tab.key !== params.type
      }
    })
  }, [params.type])
  const columns = useMemo(() => {
    return selectTemplatesColumns
  }, [])
  const detailComp = useMemo(() => {
    if (!detail || !detail?.id) {
      return null
    }
    if (dataSource?.some(({ id }) => id === detail.id)) {
      const indexDetail = dataSource.findIndex(({ id }) => id === detail.id)
      if (indexDetail !== -1 && indexDetail !== 0) {
        const newDataSource = [...dataSource]
        newDataSource[indexDetail] = dataSource?.[0]
        newDataSource[0] = dataSource?.[indexDetail]
        setDataSource(newDataSource)
        document
          .getElementsByClassName('ant-table-body')?.[0]
          ?.scrollTo({ top: 0, behavior: 'smooth' })
      }
      return null
    }
    return <DetailComponent key={detail.id} columns={columns} record={detail} />
  }, [columns, dataSource, detail])

  const fetchDataSource = useCallback(
    async (input: CampaignStepSelectTemplatesParams) => {
      setLoading(true)
      const { data, meta } = await fetchStepSelectTemplates(input)
      setDataSource(data)
      setParams((prs) => {
        return {
          ...prs,
          ...input,
          pagination: {
            ...prs.pagination,
            total: meta.total_items || input.pagination.total,
            current: meta.current_page || input.pagination.current,
            pageSize: meta.per_page || input.pagination.pageSize
          }
        }
      })
      setLoading(false)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [params.site]
  )

  const handleSearch = useCallback(
    (keyword: string) => {
      const newParams = {
        ...params,
        keyword,
        pagination: { ...params.pagination, current: 1 }
      }
      setParams(newParams)
      if (debounce.current) clearTimeout(debounce.current)
      debounce.current = setTimeout(() => {
        fetchDataSource(newParams)
      }, 300)
    },
    [fetchDataSource, params]
  )

  const handleSelect = useCallback(
    (
      record: any,
      _selected: boolean,
      _selectedRows: any[],
      _nativeEvent: Event
    ) => {
      _nativeEvent.preventDefault()
      if (record && record.id) {
        const hasValue = value === parseInt(record.id)
        const newValue = hasValue ? null : parseInt(record.id)
        const newDetail = hasValue ? null : record
        setValue(newValue)
        setDetail(newDetail)
        onChange?.(newValue)
        form.setFieldValue('template_type', record.type)
        const indexDetail = dataSource.findIndex(({ id }) => id === record.id)

        if (indexDetail !== -1) {
          const newDataSource = [...dataSource]
          newDataSource[indexDetail] = dataSource?.[0]
          newDataSource[0] = dataSource?.[indexDetail]
          setDataSource(newDataSource)
          handleOnRow(record).onClick()
          document
            .getElementsByClassName('ant-table-body')?.[0]
            ?.scrollTo({ top: 0, behavior: 'smooth' })
        }
        message.success(
          <>
            Selected template : <strong>{record?.name}</strong>
          </>
        )
      }
    },
    [dataSource, onChange, value]
  )

  const handleChangeTabs = useCallback(
    (type: string) => {
      fetchDataSource({
        ...params,
        type,
        pagination: {
          ...params.pagination,
          current: 1
        }
      })
    },
    [fetchDataSource, params]
  )

  const handleChangeTable = useCallback(
    (pagi: TablePaginationConfig) => {
      fetchDataSource({
        ...params,
        pagination: {
          ...params.pagination,
          current: pagi.current,
          pageSize: pagi.pageSize
        }
      })
    },
    [fetchDataSource, params]
  )

  const handleOnRow = useCallback((record: any) => {
    return {
      onClick: () => {
        if (debounce.current) clearTimeout(debounce.current)
        debounce.current = setTimeout(async () => {
          const data = await fetchStepTemplate(record.id)
          setPreview(data)
        }, 300)
      }
    }
  }, [])

  useEffect(() => {
    ;(async () => {
      if (typeof valueProps === 'undefined' || !valueProps) {
        return
      }
      const data = await fetchStepTemplate(valueProps)
      setDetail(data.template)
      setPreview(data)
    })()
    return () => {
      if (debounce.current) {
        clearTimeout(debounce.current)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setValue(valueProps)
    if (!valueProps) {
      setDetail(null)
    }
  }, [valueProps])

  useEffect(() => {
    setParams((prs) => ({ ...prs, site }))
  }, [site])

  useEffect(() => {
    setLoading(!!loadingProps)
  }, [loadingProps])

  useEffect(() => {
    fetchDataSource(params)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchDataSource])

  useEffect(() => {
    const itemsNotDisabled = items.find(({ disabled }) => !disabled)
    if (itemsNotDisabled) {
      setParams({ ...params, type: itemsNotDisabled.key })
    }
  }, [items])

  return (
    <StyledSelectContainer ref={ref}>
      <DrawerPreviewTemplate
        data={preview}
        onClose={() => setPreview(undefined)}
        getContainer={() => document.getElementsByClassName('container_3')?.[0]}
      />
      {type === TEMPLATE_TYPE.WEB_PUSH && (
        <DrawerCreateTemplate
          open={isOpenCreateTemplate}
          key={String(isOpenCreateTemplate)}
          siteId={site}
          onClose={() => setIsOpenCreateTemplate(false)}
          getContainer={() =>
            document.getElementsByClassName('container_3')?.[0]
          }
          onCreateTemplate={(data) => {
            if (data?.template?.id) {
              setDataSource((prev) => [data.template, ...prev] as never[])
              setValue(data.template.id)
              setDetail(data.template)
              onChange?.(data.template.id)
              setIsOpenCreateTemplate(false)
            }
          }}
        />
      )}
      <TableScroll
        bordered
        loading={loading}
        columns={columns}
        dataSource={dataSource}
        pagination={params.pagination}
        onChange={handleChangeTable}
        components={{
          body: {
            row(props?: any) {
              if ((dataSource?.[0] as any)?.id === props['data-row-key']) {
                return (
                  <>
                    {detailComp}
                    <tr {...props} />
                  </>
                )
              }
              return <tr {...props} />
            }
          }
        }}
        rowKey={(record) => record.id}
        rowSelection={{
          type: 'radio',
          columnWidth: 10,
          columnTitle: ' ',
          selectedRowKeys: value ? [value] : undefined,
          onSelect: handleSelect
        }}
        onRow={handleOnRow}
        title={() => (
          <StyledStepSelectHeader>
            <StyledStepSelectHeaderTabs>
              <StyledStepSelectTabs
                activeKey={params.type}
                items={items}
                onChange={handleChangeTabs}
              />
            </StyledStepSelectHeaderTabs>
            <StyledStepSelectHeaderSearch className="flex">
              {type === TEMPLATE_TYPE.WEB_PUSH && (
                <Button
                  size="small"
                  type="link"
                  onClick={() => setIsOpenCreateTemplate(true)}>
                  Create New Template
                </Button>
              )}
              <StyledStepSelectInputSearch
                allowClear
                value={params.keyword}
                onSearch={handleSearch}
                placeholder="Search segment"
              />
            </StyledStepSelectHeaderSearch>
          </StyledStepSelectHeader>
        )}
      />
    </StyledSelectContainer>
  )
}
export const CampaignStepSelectTemplate = forwardRef(
  CampaignStepSelectTemplateWithoutRef
)
