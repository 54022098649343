import { segmentAnalyticCreate } from './SegmentAnalytic.helpers'
import { StyledSegmentAnalytic } from './SegmentAnalytic.styled'
import { FormInstance } from 'antd'
import { ModalEmpty } from 'components/atoms/modal'
import {
  ANALYTIC_WIDGET_ITEM_KEY,
  AnalyticTemplateForm,
  AnalyticWidgetMuuri,
  AnalyticWidgetMuuriProps,
  AnalyticWidgetMuuriRef,
  DEFAULT_ANALYTIC_WIDGET_ITEMS_DATA
} from 'components/molecules/analytic'
import { menuRoutes } from 'components/molecules/layout'
import {
  AnalyticTemplateWidgetItem,
  AnalyticWidgetItem
} from 'interfaces/analytic'
import {
  CSSProperties,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react'
import { useNavigate } from 'react-router'

export const SEGMENT_ANALYTIC_WIDGETS_KEYS = [
  ANALYTIC_WIDGET_ITEM_KEY.OVERALL_SCORECARD,
  ANALYTIC_WIDGET_ITEM_KEY.REACHABILITY_BY_CHANNELS,
  ANALYTIC_WIDGET_ITEM_KEY.TOP_VIEW_CATEGORIES,
  ANALYTIC_WIDGET_ITEM_KEY.BREAKDOWN_BY_AGE_AND_GENDER,
  ANALYTIC_WIDGET_ITEM_KEY.BREAKDOWN_BY_SEC_CLASS,
  ANALYTIC_WIDGET_ITEM_KEY.VISIT_BY_LOCATION,
  ANALYTIC_WIDGET_ITEM_KEY.DEVICE_USAGE,
  ANALYTIC_WIDGET_ITEM_KEY.BREAKDOWN_BY_NUMBER_OF_CHILDREN
]

export type SegmentAnalyticProps = Pick<
  AnalyticWidgetMuuriProps,
  | 'segment_id'
  | 'segment_sites'
  | 'segment_type'
  | 'end_date'
  | 'start_date'
  | 'period'
> & {
  segmentName?: string
  openCreate?: boolean
  onCancelCreate?: () => void
  isFull?: boolean
  className?: string
  style?: CSSProperties
}
export const SegmentAnalytic = (props: SegmentAnalyticProps) => {
  const {
    className,
    style,
    openCreate,
    onCancelCreate,
    isFull,
    segmentName,
    segment_id,
    segment_sites,
    segment_type,
    end_date,
    start_date,
    period
  } = props
  const navigate = useNavigate()
  const muuriRef = useRef<AnalyticWidgetMuuriRef>(null)
  const formRef = useRef<FormInstance>(null)
  const defaultValue = useMemo(() => {
    return SEGMENT_ANALYTIC_WIDGETS_KEYS.map((k, order) => {
      const data = DEFAULT_ANALYTIC_WIDGET_ITEMS_DATA[k]
      const metaData: AnalyticWidgetItem = {
        order,
        title: data.title,
        fullWidth: data.fullWidth,
        fullHeight: data.fullHeight
      }
      if (
        data.title === ANALYTIC_WIDGET_ITEM_KEY.BREAKDOWN_BY_NUMBER_OF_CHILDREN
      ) {
        metaData.showBarChart = true
      }
      return {
        widget: k,
        query: {
          period,
          segment_id,
          segment_type
        },
        data: metaData
      } as AnalyticTemplateWidgetItem
    })
  }, [period, segment_id, segment_type])
  const [loading, setLoading] = useState(false)

  const onFinish = useCallback(
    async (values: any) => {
      setLoading(true)

      const { data, errors } = await segmentAnalyticCreate({
        name: values?.name,
        description: values?.description,
        widgets: defaultValue,
        data: {
          ...values?.data,
          segment_id,
          segment_type,
          period
        }
      })
      if (errors?.length) {
        const fieldsData: any[] = []
        errors.forEach((error: any) => {
          if (error?.name === 'name') {
            fieldsData.push({
              name: 'name',
              value: values?.name,
              errors: error.messages
            })
          }
        })
        if (fieldsData.length) {
          formRef.current?.setFields(fieldsData)
        }
      }
      if (data?.id) {
        navigate(`${menuRoutes.analytic}/${data.id}`)
      }
      setLoading(false)
    },
    [defaultValue, navigate, period, segment_id, segment_type]
  )

  useEffect(() => {
    setTimeout(() => {
      muuriRef.current?.resizeHandler?.()
      window.dispatchEvent(new Event('resize'))
    }, 500)
  }, [isFull])

  useEffect(() => {
    if (!openCreate) {
      formRef.current?.resetFields()
      setLoading(false)
    }
  }, [openCreate])

  return (
    <StyledSegmentAnalytic className={className} style={style}>
      {!!segmentName && (
        <div className="segmentAnalytic--header flex items-center gap-6 p-4 mb-6 text-base rounded-lg border border-solid border-neutral100 bg-white">
          <p className="flex items-center gap-4 m-0">
            <span>Segment</span>
            <strong>{segmentName}</strong>
          </p>
        </div>
      )}
      <AnalyticWidgetMuuri
        ref={muuriRef}
        value={defaultValue}
        segment_id={segment_id}
        segment_sites={segment_sites}
        segment_type={segment_type}
        end_date={end_date}
        start_date={start_date}
        period={period}
      />

      <ModalEmpty
        open={openCreate}
        onCancel={onCancelCreate}
        title="Create analytic template"
        okButtonProps={{ loading }}
        onOk={() => formRef.current?.submit()}>
        <AnalyticTemplateForm ref={formRef} onFinish={onFinish} />
      </ModalEmpty>
    </StyledSegmentAnalytic>
  )
}
