import Icon from '@ant-design/icons'
import cn from 'classnames'
import { MainHeader, menuRoutes } from 'components/molecules'
import { PERMISSIONS } from 'constants/permission'
import { theme } from 'constants/theme'
import { FacebookImage } from 'icons/V2'
import { useNavigate } from 'react-router'

export const INTEGRATION_ITEM_KEYS = {
  fb_conversions_api: 'fb_conversions_api',
  fb_ad_account: 'fb_ad_account',
  fb_lead_audience: 'fb_lead_audience'
}

const INTEGRATION_ITEMS = {
  [INTEGRATION_ITEM_KEYS.fb_conversions_api]: {
    icon: FacebookImage,
    label: 'Facebook Conversions API',
    description:
      'Sidis lets you connect Facebook Conversions with thousands of the most popular apps, so you can automate your work and have more time for what matters most—no code required.'
  },
  [INTEGRATION_ITEM_KEYS.fb_ad_account]: {
    icon: FacebookImage,
    label: 'Facebook Custom Audience API',
    description:
      'Custom Audience is an ad targeting option that lets you find your existing audiences among people across Meta technologies.'
  },
  [INTEGRATION_ITEM_KEYS.fb_lead_audience]: {
    icon: FacebookImage,
    label: 'Facebook Lead Audience',
    description:
      'SIDIS seamlessly connect with Facebook Lead Ads to capture and manage leads in real time. Create new lead segments directly from Facebook Ads campaigns, ensuring accurate and automated data sync. Optimize your marketing efforts with enriched insights and faster follow-ups—all within your CRM platform.'
  }
}

export default function IntegrationPage() {
  const navigate = useNavigate()
  return (
    <MainHeader
      headerTitle="Integration"
      bgColor={theme.colors.neutral50}
      stickyHeader
      permissions={[PERMISSIONS.integration_view]}>
      <div className="w-full max-w-[1152px] mx-auto p-6">
        <div className="bg-white p-6 rounded-2xl border border-solid border-neutral100">
          <h1 className="mb-2 text-base font-semibold">Integration items</h1>
          <div className="integration--list flex flex-row flex-wrap -m-2">
            {Object.keys(INTEGRATION_ITEMS).map((k) => {
              const key = k as keyof typeof INTEGRATION_ITEMS
              const item = INTEGRATION_ITEMS[key]

              return (
                <div className="p-2 w-1/2" key={`${key}`}>
                  <div
                    className={cn(
                      'integration--item',
                      'h-full',
                      'p-4 border border-solid border-neutral100 rounded-2xl cursor-pointer'
                    )}
                    onClick={() =>
                      navigate(`${menuRoutes.integration}/${key}`)
                    }>
                    {item?.icon && (
                      <Icon
                        component={item.icon}
                        className="text-[28px] leading-[0] mb-4"
                      />
                    )}
                    <h3 className="mb-2 text-base font-semibold">
                      {item.label}
                    </h3>
                    <p className="m-0 text-sm text-gray600">
                      {item.description}
                    </p>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </MainHeader>
  )
}
