import {
  StyledAlert,
  StyledButton,
  StyledButtonAddEvent,
  StyledEmpty,
  StyledFormError,
  StyledItem,
  StyledItemHeader,
  StyledItemTitle,
  StyledList,
  StyledSelect
} from './index.styled'
import Icon from '@ant-design/icons'
import { DatePicker, Form, FormInstance, FormListFieldData, Input } from 'antd'
import cn from 'classnames'
import { SelectEvent, SelectEventField } from 'components/atoms/select'
import { getFormNamePath } from 'components/condition/conditions/form/index.helpers'
import { OPERATOR_OPTIONS_BY_TYPE } from 'components/molecules/segment/SegmentForm/SegmentFormQuery/SegmentCondition/SegmentCondition.helpers'
import { SegmentConditionValue } from 'components/molecules/segment/SegmentForm/SegmentFormQuery/SegmentCondition/SegmentConditionValue'
import { useEventOperationOptions } from 'hooks/useSegment'
import { Close, Plus } from 'icons'
import { OptionType } from 'interfaces'
import {
  CSSProperties,
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react'
import { FieldEventServices } from 'services/fieldAttribute'
import { filterOption } from 'utils'

export type FormEventProps = {
  site?: string | number
  name: string
  showEvent?: boolean
  showDate?: boolean
  disabledEvent?: boolean
  disabledValidateEvent?: boolean
  disabledEventFields?: Array<string | number>
  disabledConditions?: boolean
  callbackFieldOptions?: (values: FormEventOptions) => void
  disabledValidateConditions?: boolean
  className?: string
  style?: CSSProperties
}

export function FormEvent(props: FormEventProps) {
  const form = Form.useFormInstance()

  if (!form) {
    return (
      <StyledAlert
        type="warning"
        message="Put component inside Form component"
      />
    )
  }

  return <FormEventConditionsInstance {...props} form={form} />
}

type FormEventInstanceProps = FormEventProps & { form: FormInstance }
function FormEventConditionsInstance(props: FormEventInstanceProps) {
  const {
    name = 'events_conditions',
    showDate = true,
    showEvent = true,
    disabledEvent = false,
    disabledValidateEvent,
    disabledEventFields,
    disabledConditions = false,
    callbackFieldOptions,
    disabledValidateConditions,
    className,
    style
  } = props

  return (
    <Form.List
      name={name}
      rules={[
        {
          async validator(rule: any, value: any, callback: any) {
            if (!!disabledValidateEvent) {
              return true
            }
            if (!value || !value?.length) {
              return Promise.reject('This field condition is required!')
            }
          }
        }
      ]}>
      {(events, { add: addEvent }, { errors }) => {
        const onAddEvent = () => addEvent({})
        return (
          <StyledList
            className={cn(className, 'formEvent__list')}
            style={style}>
            {!events?.length && !disabledConditions && (
              <div className="formEvent__empty">
                <StyledEmpty description="No events">
                  <StyledButtonAddEvent
                    type="primary"
                    onClick={onAddEvent}
                    disabled={!!disabledConditions}
                    icon={<Icon component={Plus} />}>
                    Add event
                  </StyledButtonAddEvent>
                </StyledEmpty>
                <StyledFormError errors={errors} />
              </div>
            )}
            {events.map((event) => {
              return (
                <FormEventList
                  {...event}
                  key={event.key}
                  rootName={name}
                  showEvent={showEvent}
                  disabledEvent={disabledEvent}
                  disabledEventFields={disabledEventFields}
                  disabledConditions={!!disabledConditions}
                  showDate={showDate}
                  callbackFieldOptions={callbackFieldOptions}
                  disabledValidateConditions={disabledValidateConditions}
                />
              )
            })}
          </StyledList>
        )
      }}
    </Form.List>
  )
}

export type FormEventOptions = Array<{
  value: number | string
  label: string
  type: string
  values: Array<{ key: string; value: string }> | null
}>
type FormEvenListProps = FormListFieldData &
  Pick<
    FormEventProps,
    | 'disabledEvent'
    | 'disabledEventFields'
    | 'disabledConditions'
    | 'showEvent'
    | 'showDate'
    | 'callbackFieldOptions'
    | 'disabledValidateConditions'
  > & {
    rootName: string
  }
function FormEventList(props: FormEvenListProps) {
  const {
    rootName: rootNameProps,
    showEvent,
    disabledEvent,
    disabledEventFields,
    disabledConditions,
    showDate,
    callbackFieldOptions,
    disabledValidateConditions,
    ...event
  } = props
  const form = Form.useFormInstance()
  const [eventFieldOptions, setEventFieldOptions] = useState<FormEventOptions>(
    []
  )
  const rootName = useMemo(
    () => [rootNameProps, event.name],
    [event.name, rootNameProps]
  )

  const eventId = Form.useWatch(getFormNamePath(rootName, 'event_id'))
  const eventIdRef = useRef(eventId)
  const eventFieldOperationOptions = useEventOperationOptions()

  const listRules = useMemo(
    () => [
      {
        async validator(rule: any, value: any, callback: any) {
          if (!!disabledValidateConditions) {
            return true
          }
          if (!value || !value?.length) {
            return Promise.reject('This condition fields is required!')
          }
        }
      }
    ],
    [disabledValidateConditions]
  )

  const onChangeEventId = useCallback(
    (eventIdValue) => {
      if (eventIdRef.current !== eventIdValue) {
        eventIdRef.current = eventIdValue
        form.setFieldValue(getFormNamePath(rootName, 'conditions'), [])
      }
    },
    [form, rootName]
  )

  useEffect(() => {
    ;(async () => {
      if (!eventId) {
        return
      }
      try {
        const fieldCondRes = await FieldEventServices.getListFields({
          event_id: eventId.toString()
        })
        if (!fieldCondRes.data?.data && fieldCondRes.data.error) {
          throw new Error(fieldCondRes.data.error)
        }
        const fieldOptions =
          fieldCondRes.data?.data?.map((eventField: any) => {
            return {
              label: eventField.name,
              value: eventField.field,
              type: eventField.type,
              values: eventField.values || null
            }
          }) || []
        callbackFieldOptions?.(fieldOptions)
        setEventFieldOptions(fieldOptions)
      } catch (error) {
        console.log('** Error : ', error)
      }
    })()
  }, [eventId])

  return (
    <Fragment>
      {showEvent && (
        <Form.Item
          name={[event.name, 'event_id']}
          rules={
            !disabledEvent
              ? [{ message: 'This field is required!', required: true }]
              : undefined
          }>
          <SelectEvent
            allowClear
            showSearch
            filterOption={filterOption as any}
            placeholder="Event"
            onChange={onChangeEventId}
            disabled={disabledEvent}
          />
        </Form.Item>
      )}
      {showDate && (
        <Form.Item
          name={[event.name, 'submitted']}
          rules={[{ message: 'This field is required!', required: true }]}>
          <DatePicker.RangePicker />
        </Form.Item>
      )}
      <Form.List name={[event.name, 'conditions']} rules={listRules}>
        {(conditions, { add: addCond, remove: removeCond }, { errors }) => {
          const onRemoveCond = (index: number | number[]) => () =>
            removeCond(index)
          const onAddCond = () => addCond({})

          return (
            <Fragment>
              <StyledList className="formEvent__condition_list">
                {!conditions.length && !disabledConditions && (
                  <>
                    <StyledEmpty
                      image={StyledEmpty.PRESENTED_IMAGE_SIMPLE}
                      description="There is no condition fields!"
                    />
                    <StyledFormError errors={errors} />
                  </>
                )}
                {conditions.map((cond) => {
                  return (
                    <FormEventCondition
                      {...cond}
                      key={cond.key}
                      rootName={getFormNamePath(rootName, [
                        'conditions',
                        cond.name
                      ])}
                      remove={onRemoveCond(cond.name)}
                      field_options={eventFieldOptions}
                      field_operation_options={eventFieldOperationOptions}
                      disabled_event_fields={disabledEventFields || []}
                      disabledConditions={disabledConditions}
                    />
                  )
                })}
              </StyledList>
              {!disabledConditions && (
                <StyledButton
                  block
                  size="small"
                  icon={<Icon component={Plus} />}
                  onClick={onAddCond}
                  className="add_field_btn"
                />
              )}
            </Fragment>
          )
        }}
      </Form.List>
    </Fragment>
  )
}

export type FormEventConditionProps = FormListFieldData & {
  rootName: Array<string | number>
  field_options: FormEventOptions
  field_operation_options: OptionType[]
  disabled_event_fields: Array<string | number>
  disabledConditions?: boolean
  remove: () => void
}
export function FormEventCondition(props: FormEventConditionProps) {
  const form = Form.useFormInstance()
  const {
    rootName,
    field_options,
    field_operation_options,
    disabled_event_fields,
    disabledConditions,
    remove,
    ...cond
  } = props

  const condValue = Form.useWatch(getFormNamePath(rootName), form)
  const condValueType = useMemo(() => {
    return field_options.find(
      (fieldOption) => fieldOption.value === condValue?.field
    )?.type
  }, [condValue?.field, field_options])
  const operatorOptions = useMemo(() => {
    return OPERATOR_OPTIONS_BY_TYPE?.[
      (condValue?.type ||
        condValueType) as keyof typeof OPERATOR_OPTIONS_BY_TYPE
    ]
  }, [condValue?.type, condValueType])
  const valueOptions = useMemo(() => {
    return field_options
      .find(
        (fieldOption) =>
          fieldOption.value === condValue?.type ||
          fieldOption.value === condValueType
      )
      ?.values?.map(({ key, value }) => ({ value, label: key }))
  }, [condValue?.type, condValueType, field_options])

  const handleChangeConditionField = useCallback(
    (value: any) => {
      const indexValue = condValue
      if (indexValue) {
        const fieldOption = field_options.find(
          (fieldOption) => fieldOption.value === value
        )
        setTimeout(() => {
          const type = getFormNamePath(rootName, 'type')
          form.setFields([
            {
              name: type,
              value: fieldOption?.type,
              touched: true
            }
          ])
        }, 0)
      }
    },
    [condValue, field_options, form, rootName]
  )

  return (
    <StyledItem key={cond.name} className="formEvent__condition_item">
      <Form.Item name={[cond.name, 'type']} hidden>
        <Input />
      </Form.Item>
      <StyledItemHeader>
        <StyledItemTitle>Field conditions</StyledItemTitle>
        <StyledButton
          className="remove_field_btn"
          type="link"
          onClick={remove}
          disabled={disabledConditions}
          icon={<Icon component={Close} />}
        />
      </StyledItemHeader>
      <Form.Item
        name={[cond.name, 'field']}
        rules={[
          {
            message: 'This field is required!',
            required: true
          }
        ]}>
        <SelectEventField
          size="small"
          placeholder="Field"
          options={field_options}
          disabled_event_fields={disabled_event_fields}
          onChange={handleChangeConditionField}
          disabled={disabledConditions}
        />
      </Form.Item>
      <Form.Item
        name={[cond.name, 'operator']}
        rules={[
          {
            message: 'This field is required!',
            required: true
          }
        ]}>
        <StyledSelect
          size="small"
          placeholder="Operator"
          options={operatorOptions as any}
          disabled={disabledConditions}
        />
      </Form.Item>
      <Form.Item
        name={[cond.name, 'value']}
        rules={[
          {
            message: 'This field is required!',
            required: true
          }
        ]}>
        <SegmentConditionValue
          type={condValue?.type || condValueType}
          operator={condValue?.operator}
          options={valueOptions}
          disabled={disabledConditions}
        />
      </Form.Item>
    </StyledItem>
  )
}
