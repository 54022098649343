import { Box } from '../box'
import Icon from '@ant-design/icons'
import { Button, Skeleton } from 'antd'
import cn from 'classnames'
import {
  PreviewAppPush,
  PreviewWebPush
} from 'components/molecules/templateBuilder'
import { TEMPLATE_TYPE } from 'constants/template'
import { useApp } from 'context/App'
import { useZaloTemplates } from 'hooks/useZaloTemplates'
import { Iphone, ScreenEmpty } from 'icons'
import { TemplateBuilderPreviewIframe } from 'pages/templateBuilderCreate/TemplateBuilderPreview'
import View from 'pages/templateBuilderCreate/whatsApp/Preview/view'
import PreviewZalo from 'pages/templateBuilderCreate/zalo/Preview'
import { useState } from 'react'

export type TemplatePreviewProps = {
  loading?: boolean
  type?: string
  previewInfo?: any
}
export const TemplatePreview = (props: TemplatePreviewProps) => {
  const { loading, type, previewInfo } = props
  const { sites } = useApp()
  const [previewMode, setPreviewMode] = useState('mobile')
  const { listZaloTemplate } = useZaloTemplates()
  if (!type) {
    return null
  }

  const foundZns =
    type === TEMPLATE_TYPE.ZALO
      ? listZaloTemplate.find(
          (zt) => zt.zns_template_id === previewInfo?.template?.zns_template_id
        )
      : undefined

  const subject_line = previewInfo?.subject_line || ''

  return (
    <Skeleton active loading={loading} paragraph={{ rows: 5 }}>
      {type === TEMPLATE_TYPE.EMAIL && (
        <div className={cn('flex flex-col h-full')}>
          {subject_line && (
            <h3 className="subjectTitle">
              <span className="text-sm text-gray600 font-normal">
                Subject line :&nbsp;
              </span>
              {subject_line}
            </h3>
          )}
          <div
            className={cn(
              'w-full h-full',
              'flex-1 min-h-0',
              '[&.desktop]:border [&.desktop]:border-solid [&.desktop]:border-neutral100 [&.desktop]:rounded-lg [&.desktop]:bg-neutral50',
              previewMode
            )}>
            <TemplateBuilderPreviewIframe
              isMobile={Boolean(previewMode === 'mobile')}
              htmlTemplate={previewInfo?.template?.body_html || ''}
              className={cn('rounded-lg')}
            />
            <div
              className={cn(
                'absolute z-10 bottom-10 right-10 bg-white rounded-md flex flex-col shadow-sm p-1'
              )}>
              <Button
                type="link"
                onClick={() => setPreviewMode('desktop')}
                icon={<Icon component={ScreenEmpty} />}
                className={cn(
                  { active: previewMode === 'desktop' },
                  '[&.active]:bg-primary [&.active]:text-white',
                  'w-[24px] h-[24px] p-0 cursor-pointer rounded-md text-black'
                )}
              />
              <Button
                type="link"
                onClick={() => setPreviewMode('mobile')}
                icon={<Icon component={Iphone} />}
                className={cn(
                  { active: previewMode === 'mobile' },
                  '[&.active]:bg-primary [&.active]:text-white',
                  'w-[24px] h-[24px] p-0 cursor-pointer rounded-md text-black'
                )}
              />
            </div>
          </div>
        </div>
      )}
      {type === TEMPLATE_TYPE.WEB_PUSH && (
        <PreviewWebPush
          preview={{
            name: previewInfo?.template?.name,
            message_type: previewInfo?.message_type,
            title: previewInfo?.title,
            description: previewInfo?.description || '',
            message_icon: previewInfo?.message_icon,
            image: previewInfo?.image,
            message_link: previewInfo?.message_link,
            custom_link_url: previewInfo?.custom_link_url,
            utm_source: previewInfo?.utm_source,
            utm_medium: previewInfo?.utm_medium,
            utm_campaign: previewInfo?.utm_campaign,
            utm_content: previewInfo?.utm_content,
            utm_term: previewInfo?.utm_term,
            site: previewInfo?.template.site,
            country_code: previewInfo?.template.country_code,
            site_name: previewInfo?.template.site_name
          }}
          sites={sites}
        />
      )}
      {type === TEMPLATE_TYPE.APP_PUSH && (
        <PreviewAppPush
          preview={{
            title: previewInfo?.title,
            push_message: previewInfo?.push_message,
            image: previewInfo?.image
          }}
        />
      )}
      {[
        TEMPLATE_TYPE.WHATSAPP,
        TEMPLATE_TYPE.TWILIO,
        TEMPLATE_TYPE.VNPT
      ].includes(type) && (
        <View
          bodyExample={previewInfo?.body_example || ''}
          bodyText={
            previewInfo?.body_text || previewInfo?.template?.body_html || ''
          }
          bodyVariable={previewInfo?.body_variable || []}
          footerText={previewInfo?.footer_text || ''}
          headerExample={previewInfo?.header_example || ''}
          headerFormat={previewInfo?.header_format || ''}
          headerHandle={previewInfo?.header_handle || ''}
          headerText={previewInfo?.header_text || ''}
          headerVariable={previewInfo?.header_variable || []}
          buttons={previewInfo?.buttons || []} // TODO
          isMedia={type === TEMPLATE_TYPE.WHATSAPP}
        />
      )}
      {type === TEMPLATE_TYPE.ZALO && foundZns && (
        <Box className="flex w-full justify-center items-center h-full">
          <PreviewZalo previewUrl={foundZns.preview_url} />
        </Box>
      )}
    </Skeleton>
  )
}
